<template>
  <main class="main">
    <nav class="breadcrumb-nav border-0 mb-0">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/">Home</router-link>
          </li>
          <li class="breadcrumb-item active">{{ $t("payment_success") }}</li>
        </ol>
      </div>
    </nav>

    <div class="page-content">
      <div class="container">
        <div class="row justify-content-around">
          <div class="col-lg-5 mb-2 mb-lg-0">
            <div class="order-confirmation">
              <h2 class="title text-primary mb-1">
                {{ $t("thank_you_for_your_order") }}
              </h2>
              <p class="text-dark mt-2">
                {{ $t("your_order_number_is", { orderNo: transactionId }) }}
              </p>
              <p class="text-dark mb-2">{{ $t("payment_success_msg") }}</p>
              <router-link to="/">
                <font-awesome-icon icon="fa-solid fa-angle-left" />
                {{ $t("continue_shopping") }}</router-link
              >
              <!-- <p class="mt-1">
                {{ $t('email_address') }} -->
              <!-- <b-link :href="'mailto:' + user.email">{{ user.email }}</b-link>
                {{ $t("email_address") }}
                <b-link href="mailto:r.dekeijzer@e-tailors.com">
                  r.dekeijzer@e-tailors.com
                </b-link>
              </p> -->

              <!-- <router-link to="/account/">
                <button class="order-confirmation-button mt-2">
                  {{ $t("account_create") }}
                </button>
              </router-link> -->
            </div>
          </div>

          <div class="col-lg-5">
            <div class="order-confirmation">
              <div class="gift-card">
                <div class="order-confirmation-image">
                  <img
                    src="@/esf_weert_mobilesupplies/assets/static/images/icons/gift-Icon.png"
                    alt="gift"
                    width="300"
                  />
                </div>
                <div class="order-confirmation-details">
                  <h5 class="text-primary">{{ $t("gift_waiting") }}</h5>
                  <p class="text-dark">{{ $t("thank_you_purchase") }}</p>
                  <button class="btn btn-dark mt-2">
                    {{ $t("choose_voucher") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { getOrderInfoFromHash } from "@storefront/core/data-resolver/cart";
import { checkEmailExist } from "@storefront/core/data-resolver/user";
import config from "@storefront/core/lib/utils/config";

export default {
  data: () => ({
    emailExists: true,
    renderComponent: true,
    showPasswordField: false,
    form: {
      password: "",
      password_confirm: "",
    },
    order_id: null,
  }),
  computed: {
    transactionId() {
      if (typeof this.$store.state.route.query.transactionid != "undefined") {
        return this.$store.state.route.query.transactionid;
      }
      return null;
    },

    user: {
      get() {
        return this.$store.getters["user/getCurrentUser"];
      },
      set() {},
    },
    // success_page() {
    //     return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
    //         "success_page"
    //     );
    // },
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
  },
  async mounted() {
    const bcrumb = { current: "success", routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);

    if (this.transactionId != null) {
      const hashId = localStorage.getItem("orderHash");
      const order = await getOrderInfoFromHash(hashId);
      this.order_id = parseInt(atob(order.id));
      if (!this.$store.getters["user/getIsLoggedIn"]) {
        this.user.email = order.customer_email;
        if (!(await checkEmailExist(this.user.email))) {
          this.emailExists = true;
        } else {
          this.emailExists = false;
        }
      }
      if (order != false) {
        this.user.email = order.customer_email;
        if (this.$gtm.enabled() == true) {
          const items = [];
          order.items.forEach((element) => {
            let item = {};
            item.id = element.sku;
            item.name = element.name;
            item.price = element.product_sale_price;
            item.quantity = element.quantity_ordered;
            items.push(item);
          });
          if (this.$gtm.enabled()) {
            window.dataLayer.push({
              event: "purchase",
              ecommerce: {
                currencyCode: "EUR",
                purchase: {
                  actionField: {
                    id: this.transactionId,
                    affiliation: config.app_name,
                    revenue: order.total.subtotal.value,
                    tax: order.total.total_tax.value,
                    shipping: order.total.total_shipping.value,
                  },
                  products: items,
                },
              },
            });
          }
        }
      }
    }
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.$t("payment_success") },
        { name: "keywords", content: this.$t("payment_success") },
        { name: "description", content: this.$t("payment_success") },
      ],
      title: this.$t("payment_success"),
    };
  },
};
</script>
